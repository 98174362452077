import request from '@/util/request';
import qs from "qs";

export function getList(query) {
    return request({
        url: '/employee',
        method: 'get',
        params: query
    });
};

export function get(id) {
    return request({
        url: `/employee/${id}`,
        method: 'get',
    });
};

export function bizCardList() {
    return request({
        url: `/employee/bizCardList`,
        method: 'get',
    });
};

export function save(data) {
    return request({
        url: '/employee',
        method: 'post',
        data: qs.stringify(data)
    });
}

export function remove(id) {
    return request({
        url: `/employee/${id}`,
        method: 'delete'
    });
}
