<template>
    <ul>
        <li v-for="item in dataList" :key="item.id">
            <div class="node" @click="select(item)" :style="{ paddingLeft: paddingLeft }" :class="{ active: item._active }">
                <i
                    @click.stop="expand(item)"
                    class="nazaio-iconfont nz-caret-right"
                    :class="{ invisible: !(item.children && item.children.length), expand: item._expand }"
                />
                <div class="node-info">
                    <i class="nazaio-iconfont nz-orgs-fill" />
                    <h1>{{ item.title }}</h1>
                    <button v-if="item._active" class="action-btn" @click.stop="more">
                        <Poptip
                            v-model="showPop"
                            placement="bottom-end"
                            width="90"
                            offset="12"
                            :transfer="true"
                            class="more-action"
                            popper-class="poper"
                        >
                            <i class="nazaio-iconfont nz-more-vert" />
                            <div slot="content" class="sub-btns">
                                <button @click="edit(item)"><i class="nazaio-iconfont nz-edit-square" />编辑</button>
                                <button @click="remove(item)"><i class="nazaio-iconfont nz-delete" />删除</button>
                            </div>
                        </Poptip>
                    </button>
                </div>
            </div>
            <build-tree
                :class="{ expand: item._expand }"
                v-if="item.children && item.children.length"
                :data="item.children"
                :level="level + 1"
                @select="onSelect"
                @clearActive="onClearActive"
                @edit="onEdit"
                @remove="onRemove"
            ></build-tree>
        </li>
    </ul>
</template>
<script>
export default {
    name: "BuildTree",
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        level: {
            type: Number,
            default: 1,
        },
    },
    created() {
        this.dataList = this.data;
        this.initData();
    },
    data() {
        return {
            dataList: [],
            showPop: false
        };
    },
    computed: {
        paddingLeft() {
            return this.level * 16 + "px";
        },
    },
    methods: {
        initData() {
            for (let i = 0; i < this.dataList.length; i++) {
                if (this.dataList[i]._active === undefined) {
                    this.$set(this.dataList[i], "_active", false);
                    this.$set(this.dataList[i], "_expand", false);
                }
            }
        },
        expand(node) {
            node._expand = !node._expand;
        },
        select(node) {
            if (!node._active) {
                if (this.level > 1) {
                    this.$emit("clearActive");
                } else {
                    this.onClearActive();
                }
                node._active = true;
                this.$emit("select", node);
            } else {
                node._active = false;
                this.$emit("select", null);
            }
        },
        onSelect(node) {
            this.$emit("select", node);
        },
        onClearActive() {
            if (this.level === 1) {
                if (this.dataList && this.dataList.length) {
                    this.clearActive(this.dataList);
                }
            } else {
                this.$emit("clearActive");
            }
        },
        clearActive(nodeList) {
            for (let i = 0; i < nodeList.length; i++) {
                nodeList[i]._active = false;
                if (nodeList[i].children && nodeList[i].children.length) {
                    this.clearActive(nodeList[i].children);
                }
            }
        },
        more() {
            console.log(1);
        },
        edit(item) {
            this.showPop = false;
            this.$emit("edit", item);
        },
        onEdit(item) {
            this.$emit("edit", item);
        },
        remove(item) {
            this.showPop = false;
            this.$emit("remove", item);
        },
        onRemove(item) {
            this.$emit("remove", item);
        }
    },
    watch: {
        data(val) {
            this.dataList = val;
            this.initData();
        },
    },
};
</script>
<style lang="less" scoped>
ul {
    padding: 0;
    list-style: none;

    ul {
        display: none;

        &.expand {
            display: unset;
        }
    }
}

.node {
    white-space: nowrap;
    position: relative;
    padding-right: 32px;
    cursor: pointer;

    &:hover {
        background-color: #daf2ed;
    }

    &.active {
        background-color: #daf2ed;

        .action-btn {
            display: unset;
        }
    }

    .nz-caret-right,
    .node-info,
    .nz-orgs-fill,
    h1,
    button {
        display: inline-block;
    }

    .nz-caret-right {
        margin-right: 2px;
        font-size: 14px;
        cursor: default;
        transition: transform 100ms ease;
        transform-origin: center center;
        -webkit-transition: transform 100ms ease;
        -webkit-transform-origin: center center;

        &:hover {
            color: @fc-main-color;
        }

        &.invisible {
            visibility: hidden;
        }

        &.expand {
            transform: rotate(90deg);
        }
    }

    .node-info {
        cursor: pointer;
        // position: relative;
        padding-right: 10px;
    }

    .nz-orgs-fill {
        margin-right: 8px;
        color: @fc-main-color;
        font-size: 14px;
    }

    h1 {
        font-size: 14px;
        color: #395069;
        margin: 0;
        height: 32px;
        line-height: 32px;
        font-weight: normal;
    }

    .action-btn {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 32px;
        height: 32px;
        border: none;
        outline: none;
        color: #fff;
        background-color: @fc-main-color;
        cursor: pointer;

        .nz-more-vert {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.sub-btns {
    button {
        display: block;
        width: 90px;
        height: 28px;
        line-height: 28px;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 12px;
        color: #395069;

        i {
            font-size: 14px;
            margin-right: 8px;
        }

        &:hover {
            background-color: #f8f8f8;
        }
    }
}
</style>
<style lang="less">
.poper {
    min-width: 90px;

    .ivu-poptip-body {
        padding: 0;
    }

    .ivu-poptip-body-content {
        overflow: hidden;
    }
}
</style>