import {getUserSignature, getCmsSignature} from "@/api/oss-api";
import axios from "axios";
import x2js from "x2js";

export function ossUserUpload(fileObject, callback) {
    getUserSignature().then(response => {
        let ossData = new FormData();
        ossData.append("OSSAccessKeyId", response.accessid);
        ossData.append("policy", response.policy);
        ossData.append("Signature", response.signature);
        ossData.append("key", response.dir + new Date().getTime() + get_suffix(fileObject.name));
        ossData.append("success_action_status", 201);
        ossData.append("file", fileObject);
        axios.post(response.host, ossData).then(res => {
            if (res.status !== 201) {
                console.log(res);
                return;
            }
            let result = new x2js().xml2js(res.data);
            result.PostResponse.resourceUrl = response.resourceUrl;
            result.PostResponse.fileName = fileObject.name;
            callback(result.PostResponse);
        }).catch(error => console.log(error));
    });
}

export function ossCmsUpload(fileObject, callback) {
    getCmsSignature().then(response => {
        let ossData = new FormData();
        ossData.append("OSSAccessKeyId", response.accessid);
        ossData.append("policy", response.policy);
        ossData.append("Signature", response.signature);
        ossData.append("key", response.dir + new Date().getTime() + get_suffix(fileObject.name));
        ossData.append("success_action_status", 201);
        ossData.append("file", fileObject);
        axios.post(response.host, ossData).then(res => {
            if (res.status !== 201) {
                console.log(res);
                return;
            }
            let result = new x2js().xml2js(res.data);
            result.PostResponse.resourceUrl = response.resourceUrl;
            result.PostResponse.fileName = fileObject.name;
            callback(result.PostResponse);
        }).catch(error => console.log(error));
    });
}


function get_suffix(filename) {
    let pos = filename.lastIndexOf('.')
    let suffix = ''
    if (pos != -1) {
        suffix = filename.substring(pos)
    }
    return suffix;
}