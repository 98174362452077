<template>
    <div>
        <TitleBar @search="search" @editCompany="onEditCompany" @add="onShowEmployeeDialog">企业员工</TitleBar>
        <div class="main-container">
            <div class="employee-layout">
                <div class="department-setting">
                    <div class="title-bar">
                        <h2>组织架构</h2>
                        <button @click="onShowDepartmentDialog"><i class="nazaio-iconfont nz-add-circle-fill" /></button>
                    </div>
                    <div class="department-tree">
                        <BuildTree
                            :data="departmentTree"
                            @select="selectDepartment"
                            @edit="editDepartment"
                            @remove="removeDepartment"
                        ></BuildTree>
                    </div>
                </div>
                <div class="employee-setting">
                    <div class="title-bar">
                        <h2>{{ currentDepartment ? currentDepartment.title : "全部" }}</h2>
                    </div>
                    <div class="department-template" v-if="currentDepartment">
                        <h2>
                            推荐使用的名片模板：{{
                                currentDepartment && currentDepartment.bizCardId > 0 ? currentDepartment.bizCardName : "无"
                            }}
                        </h2>
                        <button @click="onShowCardDialog">修改名片模板</button>
                    </div>
                    <Table :columns="columns" :data="dataList" class="data-table">
                        <template slot="name" slot-scope="{ row }">
                            <span>{{ row.name }}</span>
                            <template v-if="row.leader">
                                <i class="nazaio-iconfont nz-flag-fill leader-flag" />
                                <span class="leader-span">Leader</span>
                            </template>
                        </template>
                        <template slot="action" slot-scope="{ row }">
                            <button @click="editEmployee(row.id)"><i class="nazaio-iconfont nz-edit-square" /></button>
                            <button @click="remove(row.id, row.name)"><i class="nazaio-iconfont nz-delete" />删除</button>
                        </template>
                    </Table>
                    <div class="pagination" v-if="totalSize / query.pageSize > 1">
                        <Page
                            @on-change="pageChange"
                            :current="query.pageNum"
                            :total="totalSize"
                            :pageSize="query.pageSize"
                            show-elevator
                            show-total
                        />
                    </div>
                </div>
            </div>
        </div>
        <Modal v-model="showDepartmentDialog" width="400" :title="departmentDialogTitle" class="department-dialog">
            <Form :model="editDepartmentForm" label-position="top">
                <FormItem label="部门名称">
                    <Input v-model="editDepartmentForm.name" placeholder="请输入部门名称" />
                </FormItem>
                <FormItem label="所属部门">
                    <SelectTree v-model="editDepartmentForm.parentId" :treeData="departmentTree" clearable></SelectTree>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="showDepartmentDialog = false">取消</Button>
                <Button type="primary" @click="saveDepartment">确定</Button>
            </div>
        </Modal>
        <Modal v-model="showCardDialog" width="735" title="选择名片模板" class="card-dialog" :footer-hide="true">
            <div class="set-card-box">
                <div class="card-preview">
                    <CardPreview
                        v-if="previewCardId"
                        :companyName="companyName"
                        :companyIntro="companyIntro"
                        :previewCardId="previewCardId"
                    ></CardPreview>
                    <div class="none" v-else>请选择名片模板预览</div>
                </div>
                <div class="list-panel">
                    <div class="card-list">
                        <RadioGroup v-model="previewCardId" vertical>
                            <Radio v-for="card in bizCardList" :key="card.id" :label="card.id">
                                <span>{{ card.name }}</span>
                            </Radio>
                        </RadioGroup>
                    </div>
                    <div class="create-new">
                        <button @click="$router.push({ path: '/social-card/card-template/add' })">没有您想要的？新建模板</button>
                    </div>
                    <div class="confirm"><Button type="primary" @click="updateBizCardId">确定</Button></div>
                </div>
            </div>
        </Modal>
        <Modal v-model="showEmployeeDialog" width="800" :title="employeeDialogTitle" class="employee-dialog">
            <Form :model="editEmployeeForm" ref="employeeForm" label-position="right" :label-width="110" :rules="employeeRules">
                <div class="top">
                    <div class="left">
                        <FormItem label="员工姓名" required prop="name">
                            <Input v-model="editEmployeeForm.name" placeholder="请输入员工姓名" style="width: 192px" />
                        </FormItem>
                        <FormItem label="员工手机号" required prop="mobile">
                            <Input v-model="editEmployeeForm.mobile" placeholder="请输入员工手机号" style="width: 192px" />
                        </FormItem>
                        <FormItem label="员工微信号" required prop="wechat">
                            <Input v-model="editEmployeeForm.wechat" placeholder="请输入员工微信号" style="width: 192px" />
                        </FormItem>
                        <FormItem label="员工职位" required prop="position">
                            <Input v-model="editEmployeeForm.position" placeholder="请输入员工职位" style="width: 192px" />
                        </FormItem>
                        <FormItem label="选择名片模板" required prop="bizCardId">
                            <Select :transfer="true" v-model="editEmployeeForm.bizCardId" placeholder="请选择模板" style="width: 192px">
                                <Option v-for="item in bizCardList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                            </Select>
                        </FormItem>
                    </div>
                    <div class="right">
                        <FormItem label="请上传头像" required prop="avatar">
                            <Upload
                                action=""
                                :before-upload="uploadAvatar"
                                accept="image/*"
                                :format="['jpg', 'jpeg', 'png']"
                                :max-size="2048"
                                style="width: 74px; height: 74px"
                            >
                                <div v-if="!editEmployeeForm.avatar" class="upload-btn">
                                    <i class="nazaio-iconfont nz-add" />
                                    <h2>图片上传</h2>
                                </div>
                                <div v-else class="preview-img">
                                    <img :src="editEmployeeForm.avatar" />
                                </div>
                            </Upload>
                            <p class="remark">支持jpg、png格式</p>
                        </FormItem>
                        <FormItem label="员工邮箱" required prop="email">
                            <Input v-model="editEmployeeForm.email" placeholder="请输入员工邮箱" style="width: 192px" />
                        </FormItem>
                        <FormItem label="所属部门" required prop="departmentId">
                            <SelectTree
                                v-model="editEmployeeForm.departmentId"
                                style="width: 192px"
                                :treeData="departmentTree"
                                @on-change="departmentChange"
                                clearable
                            ></SelectTree>
                        </FormItem>
                        <FormItem label="公司地址" required prop="addressId">
                            <Select :transfer="true" v-model="editEmployeeForm.addressId" placeholder="请选择公司地址" style="width: 192px">
                                <Option v-for="item in addressList" :value="item.id" :key="item.id">{{ item.address }}</Option>
                            </Select>
                        </FormItem>
                    </div>
                </div>
                <div class="leader-set">
                    <Checkbox v-model="editEmployeeForm.leader"> 设为部门leader</Checkbox>
                    <Checkbox v-model="enablePersonalIntro" style="margin-left: 20px"> 设置个人简介</Checkbox>
                </div>
                <div class="rich-text" v-if="enablePersonalIntro">
                    <vue-neditor-wrap v-model="editEmployeeForm.intro" :config="config"></vue-neditor-wrap>
                </div>
            </Form>
            <div slot="footer">
                <Button @click="showEmployeeDialog = false">取消</Button>
                <Button type="primary" @click="saveEmployee">确定</Button>
            </div>
        </Modal>
        <Modal v-model="showEditCompanyDialog" width="800" title="企业信息设置" class="company-dialog">
            <Form label-position="right" :label-width="110">
                <FormItem label="公司名称" required>
                    <Input v-model="editCompanyForm.companyName" placeholder="请输入公司名称" style="width: 620px" />
                </FormItem>
                <FormItem label="公司地址" required>
                    <div class="address-item" v-for="(address, index) in editCompanyForm.addressList" :key="index">
                        <Input v-model="address.address" placeholder="请输入地址" style="width: 580px" />
                        <button @click.prevent="removeAddress(index)"><i class="nazaio-iconfont nz-close" /></button>
                    </div>
                    <div class="add-new">
                        <Button type="primary" icon="md-add-circle" @click="newAddress">添加地址</Button>
                    </div>
                </FormItem>
                <FormItem label="公司简介">
                    <vue-neditor-wrap style="width: 620px" v-model="editCompanyForm.companyIntro" :config="config"></vue-neditor-wrap>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="showEditCompanyDialog = false">取消</Button>
                <Button type="primary" @click="saveCompanyInfo">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import TitleBar from "./components/TitleBar";
import SelectTree from "./components/TreeSelect";
import {
    save as saveDepartment,
    getList as getDepartmentTree,
    getCompanySettingInfo,
    updateCompanyInfo,
    updateBizCard,
    remove as removeDepartment,
    addressUsed,
    getDefaultCardTemplateId,
} from "@/api/department";
import {
    save as saveEmployee,
    get as getEmployee,
    bizCardList,
    getList as getEmployeeList,
    remove as removeEmployee,
} from "@/api/employee";
import { ossCmsUpload } from "@/util/oss";
import VueNeditorWrap from "vue-neditor-wrap";
import "../../../../public/neditor/xiumi-plugin/xiumi-ue-v5.css";
import CardPreview from "../components/CardPreview";
import BuildTree from "./components/BuildTree";

export default {
    components: {
        TitleBar,
        SelectTree,
        VueNeditorWrap,
        CardPreview,
        BuildTree,
    },
    created() {
        this.getCompanyInfo();
        this.getDepartmentTree();
        this.getEmployeeList();
    },
    data() {
        return {
            departmentTree: [],
            currentDepartment: undefined,
            companyName: "",
            companyIntro: "",
            addressList: [],
            editCompanyForm: {
                companyName: "",
                addressList: [
                    {
                        id: 0,
                        address: "",
                    },
                ],
                companyIntro: "",
            },
            showEditCompanyDialog: false,
            showCardDialog: false,
            previewCardId: 0,
            columns: [
                {
                    title: "ID",
                    key: "id",
                    width: 80,
                },
                {
                    title: "员工名称",
                    slot: "name",
                    width: 200,
                },
                {
                    title: "职位",
                    key: "position",
                    width: 180,
                },
                {
                    title: "名片模板",
                    key: "bizCardName",
                    width: 180,
                },
                {
                    title: "手机号",
                    key: "mobile",
                    width: 180,
                },
                {
                    title: "微信号",
                    key: "wechat",
                    width: 150,
                },
                {
                    title: "邮箱",
                    key: "email",
                    minWidth: 240,
                },
                {
                    title: "操作",
                    fixed: "right",
                    slot: "action",
                    align: "center",
                    width: 100,
                },
            ],
            totalSize: 0,
            dataList: [],
            query: {
                departmentId: 0,
                name: "",
                pageNum: 1,
                pageSize: 10,
            },
            showDepartmentDialog: false,
            editDepartmentForm: {
                id: 0,
                name: "",
                parentId: 0,
            },
            showEmployeeDialog: false,
            enablePersonalIntro: false,
            editEmployeeForm: {
                id: 0,
                name: "",
                avatar: "",
                mobile: "",
                wechat: "",
                email: "",
                position: "",
                departmentId: 0,
                bizCardId: "",
                addressId: "",
                leader: false,
                intro: "",
            },
            employeeRules: {
                name: [{ required: true, message: "请输入姓名" }],
                mobile: [{ required: true, message: "请输入手机号" }],
                wechat: [{ required: true, message: "请输入微信号" }],
                position: [{ required: true, message: "请输入职位" }],
                bizCardId: [{ required: true, message: "请设置名片模板" }],
                avatar: [{ required: true, message: "请上传头像" }],
                email: [{ required: true, message: "请输入邮箱" }],
                departmentId: [{ required: true, message: "请选择部门" }],
                addressId: [{ required: true, message: "选择公司地址" }],
            },
            bizCardList: undefined,
            config: {
                autoHeightEnabled: false,
                initialFrameHeight: 253,
                initialFrameWidth: "100%",
                UEDITOR_HOME_URL: "/neditor/",
            },
        };
    },
    computed: {
        departmentDialogTitle() {
            if (this.editDepartmentForm.id) {
                return "编辑部门";
            }
            return "添加部门";
        },
        employeeDialogTitle() {
            if (this.editEmployeeForm.id) {
                return "编辑员工";
            }
            return "添加员工";
        },
    },
    methods: {
        getCompanyInfo() {
            getCompanySettingInfo().then((response) => {
                this.companyName = response.companyName;
                this.companyIntro = response.companyIntro;
                this.addressList = response.addressList;
            });
        },
        getDepartmentTree() {
            getDepartmentTree().then((response) => (this.departmentTree = response));
        },
        getEmployeeList() {
            getEmployeeList(this.query).then((response) => {
                this.totalSize = response.totalSize;
                this.dataList = response.data;
            });
        },
        onEditCompany() {
            getCompanySettingInfo().then((response) => {
                this.editCompanyForm.companyName = response.companyName ? response.companyName : "";
                this.editCompanyForm.companyIntro = response.companyIntro ? response.companyIntro : "";
                if (!response.addressList || !response.addressList.length) {
                    this.editCompanyForm.addressList = [
                        {
                            id: 0,
                            address: "",
                        },
                    ];
                } else {
                    this.editCompanyForm.addressList = response.addressList;
                }
                this.showEditCompanyDialog = true;
            });
        },
        newAddress() {
            this.editCompanyForm.addressList.push({
                id: 0,
                address: "",
            });
        },
        removeAddress(index) {
            if (this.editCompanyForm.addressList.length <= 1) {
                this.$Message.error("至少设置一个地址！");
                return;
            }
            addressUsed(this.editCompanyForm.addressList[index].id).then((response) => {
                if (response) {
                    this.$Message.error("当前地址正在使用中！");
                    return;
                }
                this.editCompanyForm.addressList.splice(index, 1);
            });
        },
        saveCompanyInfo() {
            if (!this.editCompanyForm.companyName) {
                this.$Message.error("请输入公司名称！");
                return;
            }
            if (this.editCompanyForm.addressList.find((a) => !a.address) != null) {
                this.$Message.error("请输入公司地址！");
                return;
            }
            updateCompanyInfo(this.editCompanyForm).then(() => {
                this.$Message.success("保存成功！");
                this.showEditCompanyDialog = false;
                this.getCompanyInfo();
            });
        },
        pageChange(pageNum) {
            this.query.pageNum = pageNum;
            this.getEmployeeList();
        },
        editDepartment(department) {
            this.editDepartmentForm = {
                id: department.id,
                name: department.title,
                parentId: department.parentId,
            };
            console.log(department);
            this.showDepartmentDialog = true;
        },
        removeDepartment(department) {
            this.$Modal.confirm({
                title: "确定删除该部门吗？",
                onOk: () => {
                    removeDepartment(department.id).then(() => {
                        this.$Message.success("删除成功！");
                        if (this.currentDepartment && department.id === this.currentDepartment.id) {
                            this.currentDepartment = undefined;
                        }
                        this.getDepartmentTree();
                    });
                },
            });
        },
        resetDepartment() {
            this.editDepartmentForm = {
                id: 0,
                name: "",
                parentId: 0,
            };
        },
        saveDepartment() {
            if (!this.editDepartmentForm.name) {
                this.$Message.error("请输入部门名称！");
                return;
            }
            saveDepartment(this.editDepartmentForm).then(() => {
                this.getDepartmentTree();
                this.$Message.success("部门保存成功！");
                this.showDepartmentDialog = false;
            });
        },
        onShowDepartmentDialog() {
            this.resetDepartment();
            this.showDepartmentDialog = true;
        },
        selectDepartment(selected) {
            if (selected) {
                this.currentDepartment = selected;
            } else {
                this.currentDepartment = undefined;
            }
        },
        onShowCardDialog() {
            this.previewCardId = this.currentDepartment ? this.currentDepartment.bizCardId : 0;
            if (this.bizCardList === undefined) {
                bizCardList().then((response) => {
                    this.bizCardList = response;
                });
            }
            this.showCardDialog = true;
        },
        updateBizCardId() {
            updateBizCard(this.currentDepartment.id, this.previewCardId).then(() => {
                this.$Message.success("名片模板设置成功！");
                this.showCardDialog = false;
                this.currentDepartment.bizCardId = this.previewCardId;
                this.currentDepartment.bizCardName = this.bizCardList.find((c) => c.id === this.previewCardId).name;
            });
        },
        search(keywords) {
            this.query.name = keywords;
            this.getEmployeeList();
        },
        resetEmployeeForm() {
            this.editEmployeeForm = {
                id: 0,
                name: "",
                avatar: "",
                mobile: "",
                wechat: "",
                email: "",
                position: "",
                departmentId: 0,
                bizCardId: "",
                addressId: "",
                leader: false,
                intro: "",
            };
            this.$refs["employeeForm"].resetFields();
            this.enablePersonalIntro = false;
        },
        onShowEmployeeDialog() {
            this.resetEmployeeForm();
            if (this.bizCardList === undefined) {
                bizCardList().then((response) => {
                    this.bizCardList = response;
                });
            }
            this.showEmployeeDialog = true;
        },
        editEmployee(id) {
            this.resetEmployeeForm();
            if (this.bizCardList === undefined) {
                bizCardList().then((response) => {
                    this.bizCardList = response;
                });
            }
            getEmployee(id).then((response) => {
                this.editEmployeeForm = {
                    id: id,
                    name: response.name,
                    avatar: response.avatar,
                    mobile: response.mobile,
                    wechat: response.wechat,
                    email: response.email,
                    position: response.position,
                    departmentId: response.departmentId,
                    bizCardId: response.bizCardId,
                    addressId: response.addressId,
                    leader: response.leader,
                    intro: response.intro,
                };
                if (response.intro) {
                    this.enablePersonalIntro = true;
                }
                this.showEmployeeDialog = true;
            });
        },
        saveEmployee() {
            this.$refs["employeeForm"].validate((valid) => {
                if (!this.editEmployeeForm.departmentId) {
                    this.$Message.error("请选择所属部门！");
                    return;
                }
                if (valid) {
                    saveEmployee(this.editEmployeeForm).then(() => {
                        this.$Message.success("保存成功！");
                        this.showEmployeeDialog = false;
                        this.getEmployeeList();
                    });
                }
            });
        },
        departmentChange(departmentId) {
            if (!departmentId) {
                this.editEmployeeForm.bizCardId = 0;
                return;
            }
            getDefaultCardTemplateId(departmentId).then((response) => {
                this.editEmployeeForm.bizCardId = response;
            });
        },
        uploadAvatar(file) {
            ossCmsUpload(file, (result) => {
                this.$Message.success("上传完成！");
                if (result.resourceUrl) {
                    this.editEmployeeForm.avatar = result.resourceUrl + "/" + result.Key;
                } else {
                    this.editEmployeeForm.avatar = result.Location;
                }
            });
            return false;
        },
        remove(id, name) {
            this.$Modal.confirm({
                title: "确定删除" + name + "同事吗？",
                content: "<p>删除同事后无法恢复，如需恢复请手动添加</p>",
                onOk: () => {
                    removeEmployee(id).then(() => {
                        this.$Message.success("您已删除" + name + "同事！");
                        this.getEmployeeList();
                    });
                },
            });
        },
    },
    watch: {
        enablePersonalIntro(val) {
            if (!val) {
                this.editEmployeeForm.intro = "";
            }
        },
        currentDepartment(val) {
            if (val) {
                this.query.departmentId = val.id;
            } else {
                this.query.departmentId = 0;
            }
            this.getEmployeeList();
        },
    },
};
</script>

<style lang="less" scoped>
.employee-layout {
    display: flex;
    height: 100%;
}

.department-setting {
    background-color: #fff;
    flex: 0 0 280px;
    border-radius: 2px;
    display: flex;
    flex-flow: column nowrap;

    .title-bar {
        display: flex;
        padding: 12px 16px;
        justify-content: space-between;
        border-bottom: 1px solid #e8eaec;
        flex: 0 0 auto;

        h2 {
            font-size: 14px;
            color: #0a2a4c;
            font-weight: normal;
            margin: 0;
            height: 22px;
            line-height: 22px;
        }

        button {
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;

            i {
                color: #00b2a6;
            }
        }
    }

    .department-tree {
        flex: 1 1 auto;
        overflow: auto;
        padding: 8px 0;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d4dbe1;
            border-radius: 3px;
        }

        // /deep/ .ivu-tree-title {
        //     width: calc(100% - 12px);
        // }
        /deep/ .ivu-tree-title-selected {
            border-radius: 0;
            padding-right: 0;
        }

        /deep/ .ivu-tree-title {
            height: 32px;
            line-height: 32px;
        }

        /deep/ .ivu-tree-arrow {
            line-height: 32px;
        }
    }
}

.card-dialog {
    .set-card-box {
        display: flex;
        height: 480px;
    }

    .card-preview {
        height: 100%;
        width: 431px;
        flex: 0 0 431px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d4dbe1;
            border-radius: 3px;
        }

        .none {
            text-align: center;
            padding-top: 100px;
            height: 100%;
        }
    }

    .list-panel {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        flex: 1 1 auto;
        margin-left: 20px;

        .card-list {
            flex: 1 1 auto;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #d4dbe1;
                border-radius: 3px;
            }
        }

        .create-new {
            flex: 0 0 auto;
            margin-top: 10px;
            text-align: right;
            button {
                font-size: 12px;
                color: @fc-main-color;
                border: none;
                outline: none;
                background-color: transparent;
                cursor: pointer;
            }
        }

        .confirm {
            flex: 0 0 auto;
            margin-top: 10px;
            text-align: right;
        }
    }
}

.employee-dialog {
    .top {
        display: flex;
    }

    .left,
    .right {
        flex: 0 0 50%;
        padding: 0 20px;
    }

    .upload-btn {
        width: 74px;
        height: 74px;
        box-sizing: border-box;
        border: 1px dashed #d4dbe1;
        border-radius: 5px;
        background-color: #f8f8f8;
        overflow: hidden;
        cursor: pointer;

        i {
            font-size: 12px;
            width: 12px;
            height: 12px;
            margin: 10px auto;
            display: block;
        }

        h2 {
            font-size: 12px;
            margin: 0;
            font-weight: normal;
            color: #395069;
            text-align: center;
        }
    }

    .preview-img {
        width: 74px;
        height: 74px;

        img {
            object-fit: cover;
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .remark {
        font-size: 12px;
        color: #8494a6;
        margin-top: 4px;
        height: 15px;
        line-height: 15px;
    }

    .leader-set {
        padding: 0 20px;
    }

    .rich-text {
        margin-top: 24px;
        padding: 0 20px;
    }
}

.employee-setting {
    flex: 1 1 auto;
    overflow: hidden;
    background-color: #fff;
    margin-left: 16px;
    border-radius: 2px;
    padding: 12px 20px;

    .title-bar {
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 14px;
            color: #0a2a4c;
            font-weight: normal;
            height: 22px;
            line-height: 22px;
        }
    }

    .department-template {
        margin-top: 12px;
        display: flex;

        h2 {
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            height: 24px;
            line-height: 24px;
            color: #0a2a4c;
        }

        button {
            margin-left: 12px;
            border: none;
            outline: none;
            background-color: transparent;
            height: 24px;
            line-height: 24px;
            color: @fc-main-color;
            cursor: pointer;
        }
    }
}

.data-table {
    margin-top: 12px;

    /deep/ * {
        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d4dbe1;
            border-radius: 3px;
        }
    }

    .leader-flag {
        color: #d6c08d;
        margin-left: 6px;
    }

    .leader-span {
        color: #d6c08d;
        font-size: 12px;
    }

    button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        width: 20px;
        line-height: 20px;
        height: 20px;
        color: #395069;
        margin: 0 5px;

        &:hover {
            color: @fc-main-color;
        }
    }
}

.pagination {
    margin-top: 24px;
    text-align: right;
}

.company-dialog {
    .address-item {
        margin-top: 12px;

        &:first-child {
            margin-top: 0;
        }

        button {
            margin-left: 4px;
            width: 32px;
            height: 32px;
            background-color: transparent;
            line-height: 32px;
            border: none;
            outline: none;
            color: #8494a6;
            cursor: pointer;
        }
    }

    .add-new {
        margin-top: 12px;
    }
}
</style>