import request from '@/util/request';
import qs from "qs";

export function getList(query) {
    return request({
        url: '/department',
        method: 'get',
        params: query
    });
};

export function save(data) {
    return request({
        url: '/department',
        method: 'post',
        data: qs.stringify(data)
    });
}

export function updateBizCard(id, bizCardId) {
    return request({
        url: '/department/updateBizCard',
        method: 'post',
        data: qs.stringify({id, bizCardId})
    });
}

export function remove(id) {
    return request({
        url: `/department/${id}`,
        method: 'delete'
    });
}

export function getCompanyInfo() {
    return request({
        url: '/department/getCompanyInfo',
        method: 'get'
    });
};

export function getCompanySettingInfo() {
    return request({
        url: '/department/getCompanySettingInfo',
        method: 'get'
    });
};

export function updateCompanyInfo(data) {
    return request({
        url: '/department/updateCompanyInfo',
        method: 'post',
        data: data
    });
}

export function addressUsed(id) {
    return request({
        url: '/department/addressUsed',
        method: 'get',
        params: { id }
    });
};

export function getDefaultCardTemplateId(departmentId) {
    return request({
        url: "/department/getDefaultCardTemplateId?departmentId=" + departmentId,
        method: "get"
    });
}