<template>
    <div class="title-bar">
        <h1><slot /></h1>
        <div class="right">
            <Input
                prefix="ios-search"
                v-model="keywords"
                @keyup.enter.native="$emit('search', keywords)"
                clearable
                placeholder="按照员工姓名搜索"
                style="width: 200px; margin-right: 16px"
            />
            <Button type="primary" icon="md-add-circle" @click="$emit('add')">新建员工</Button>
            <Button style="margin-left: 10px;" @click="$emit('editCompany')">企业信息设置</Button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            keywords: "",
        };
    },
};
</script>
<style lang="less" scoped>
.title-bar {
    position: absolute;
    height: 48px;
    left: 0;
    top: 0;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 1001;

    h1 {
        font-size: 16px;
        color: #0a2a4c;
        font-weight: normal;
        margin-left: 20px;
        height: 48px;
        line-height: 48px;
        float: left;
    }

    .right {
        float: right;
        margin-top: 8px;
        margin-right: 20px;
    }
}
</style>
<style lang="less">
.return {
    margin: 8px 0 8px 20px;
    float: left;
}
</style>