<template>
    <div class="ivu-select ivu-select-default">
        <div tabindex="0" class="ivu-select-selection ivu-form-item-content">
            <div @mouseover="mouseover" @mouseleave="mouseleave">
                <div @click="clickInputShow" style="height: 32px">
                    <span v-if="labelTitle" class="ivu-select-selected-value">{{ labelTitle }}</span>
                    <span v-if="!value" class="ivu-select-placeholder" style="">请选择</span>
                </div>
                <i :class="'ivu-icon ivu-icon-' + iconVal + ' ivu-select-arrow'" @click="clickIcon"></i>
            </div>
            <div v-show="showTree" class="ivu-select-dropdown" style="max-height: 200px; overflow-y: scroll; z-index: 9999; width: 100%">
                <div style="width: 95%; margin-left: 10px">
                    <Tree :data="showData" ref="tree" @on-select-change="selectChange"></Tree>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "selectTree",
    props: {
        treeData: {
            type: Array,
        },
        value: {
            type: Number,
            default: 0,
        },
        filterable: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            queryVal: [],
            hideValue: "",
            selectNode: [],
            multipleShowVal: [],
            multipleHideVal: [],
            showTree: false,
            iconVal: "ios-arrow-down",
            showData: [],
            queryTreeVal: "",
        };
    },
    computed: {
        labelTitle() {
            return this.queryVal ? this.queryVal.map((q) => q.title).join(" / ") : "";
        },
    },
    methods: {
        clickIcon() {
            if (this.disabled) {
                if (this.iconVal === "ios-close-circle") {
                    this.clearVal();
                } else {
                    this.showSelectTree();
                }
            }
        },
        pickTree(val) {
            for (let i = 0; i < this.showData.length; i++) {
                if (this.showData[i].id === val) {
                    this.showData[i].selected = false;
                } else if (this.showData[i].children) {
                    this.recursionPickTree(val, this.showData[i].children);
                }
            }
        },
        recursionPickTree(val, data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].id == val) {
                    data[i].selected = false;
                } else if (data[i].children && data[i].children.length > 0) {
                    this.recursionPickTree(val, data[i].children);
                }
            }
        },
        clearVal() {
            if (this.disabled) {
                if (this.clearable && !this.multiple && this.iconVal === "ios-close-circle") {
                    this.pickTree(this.hideValue);
                    this.queryVal = [];
                    this.hideValue = 0;
                    if (this.showTree) {
                        this.iconVal = "ios-arrow-up";
                    } else {
                        this.iconVal = "ios-arrow-down";
                    }
                    this.$emit("input", 0);
                    this.$emit("on-change", 0);
                }
            }
        },
        clearSelect() {
            this.pickTree(this.hideValue);
            this.queryVal = [];
            this.hideValue = 0;
        },
        mouseover() {
            if (this.clearable && !this.multiple && this.iconVal !== "ios-close-circle" && this.hideValue !== 0) {
                this.iconVal = "ios-close-circle";
            }
        },
        mouseleave() {
            if (this.clearable && !this.multiple) {
                if (this.showTree) {
                    this.iconVal = "ios-arrow-up";
                } else {
                    this.iconVal = "ios-arrow-down";
                }
            }
        },
        selectChange(obj) {
            if (obj.length === 0) {
                this.queryVal = [];
                this.hideValue = 0;
                this.$emit("input", 0);
                this.$emit("on-change", 0);
            } else {
                let names = [];
                this.getName(obj[0].id, this.showData, names);
                this.queryVal = names.reverse();
                this.hideValue = obj[0].id;
                this.$emit("input", obj[0].id);
                this.$emit("on-change", obj[0].id);
            }
            this.showTree = false;
            this.iconVal = "ios-arrow-down";
        },
        getName(id, list, names) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].children) {
                    this.getName(id, list[i].children, names);
                }
                if (
                    list[i].id === id ||
                    (list[i].children && list[i].children.some((item) => names.findIndex((n) => n.id === item.id) >= 0))
                ) {
                    names.push({
                        title: list[i].title,
                        id: list[i].id,
                    });
                    return;
                }
            }
        },
        clickInputShow() {
            this.showSelectTree();
        },
        showSelectTree() {
            if (this.showTree) {
                this.showTree = false;
            } else {
                this.showTree = true;
            }
            if (this.iconVal !== "ios-close-circle") {
                if (this.iconVal === "ios-arrow-down") {
                    this.iconVal = "ios-arrow-up";
                } else if (this.iconVal === "ios-arrow-up") {
                    this.iconVal = "ios-arrow-down";
                }
            }
        },
        recursionRemoveTreeData(data, val) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].value === val) {
                    data[i].selected = false;
                    return;
                } else if (data[i].children !== undefined && data[i].children.length > 0) {
                    this.recursionRemoveTreeData(data[i].children, val);
                }
            }
        },
        recursionQueryTreeData(data, val) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].id === val) {
                    data[i].selected = true;
                } else if (data[i].children) {
                    this.recursionQueryTreeData(data[i].children, val);
                }
            }
        },
        globalClick(e) {
            if (!e.target.closest(".ivu-select-selection")) {
                this.showTree = false;
                this.iconVal = "ios-arrow-down";
            }
        },
    },
    watch: {
        treeData(val) {
            if (val) {
                this.showData = JSON.parse(JSON.stringify(val));
            } else {
                this.showData = [];
            }
            for (let i = 0; i < this.showData.length; i++) {
                if (this.showData[i].id === this.value) {
                    this.showData[i].selected = true;
                    break;
                } else if (this.treeData[i].children) {
                    this.recursionQueryTreeData(this.treeData[i].children, this.value);
                }
            }
            this.hideValue = this.value;
            let names = [];
            this.getName(this.value, this.showData, names);
            this.queryVal = names.reverse();
        },
        value(val) {
            if (!val) {
                this.clearSelect();
            } else {
                for (let i = 0; i < this.showData.length; i++) {
                    if (this.showData[i].id === val) {
                        this.showData[i].selected = true;
                        break;
                    } else if (this.treeData[i].children) {
                        this.recursionQueryTreeData(this.treeData[i].children, val);
                    }
                }
            }
            this.hideValue = val;
            let names = [];
            this.getName(val, this.showData, names);
            this.queryVal = names.reverse();
        },
    },
    mounted() {
        document.addEventListener("click", this.globalClick);
    },
};
</script>
<style>
.ivu-select-placeholder {
    display: block;
    height: 30px;
    line-height: 30px;
    color: #c5c8ce;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 22px;
}
.ivu-select-selected-value {
    display: block;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 24px;
}
</style>