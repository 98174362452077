import request from '@/util/request';

export function getUserSignature() {
    return request({
        url: '/oss/signature/user',
        method: 'get'
    });
};

export function getCmsSignature() {
    return request({
        url: '/oss/signature/cms',
        method: 'get'
    });
};